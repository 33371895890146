<template>
    <div class="loader_area3" v-if="formLoader"><quote-loader/></div>
    <div class="profile_notes" v-else>
        <div class="title">
            <h3>Forms</h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
            </div>
        </div>
        <div class="notes_wpr">
            <ul class="card_listing" v-if="forms.total">
                <li v-for="(form, key) in forms.data" :key="key">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="form">
                        <h4 @click="actionHandler(form, 'viewResponse')" class="pointer"><img src="@/assets/images/form-alt.svg">{{ form.survey_name }}</h4>
                        <div class="card_content">
                            <label :for="`form-${key}`" class="switch_option capsule_btn pt-0">
                                <h5>Show in Portal</h5>
                                <input type="checkbox" :id="`form-${key}`" @click="handleEnableDisableForm(form, $event)" :checked="form.link && form.link.client_portal == 1" hidden>
                                <div><span></span></div>
                            </label>
                            <h5>Status:</h5>
                            <span class="status" :class="{ 'opened' : form.is_response == 1, 'not_delivered': !form.is_response }">{{ form.is_response == 1 ? 'Submitted' : 'Due' }}</span>
                        </div>
                        <div class="card_footer">
                            <span class="date">{{ `${moment.utc(form.date_created).local().format('ll | LT')}` }}</span>
                        </div>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list mt-3" v-if="forms.total == 0 && !formLoader">
                <img src="@/assets/images/empty_state.svg">
                <h4>No Records Found!</h4>
            </div>
        </div>
        <div class="pagination" v-show="forms.total">
            <pagination v-model="params.page" :pages="forms.last_page" @update:modelValue="handlePagination" />
        </div>
    </div>

    <form-detail v-model="showFormDetail" :form-details="formDetails" :respondent="selectedRespondent" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const FormDetail = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/FormDetail'))

    import moment from 'moment'

    export default{
        name: 'Profile Notes',

        data () {
            return {
                showFormDetail: false,
                actions: [
                    {
                        icon: "fa-search-plus",
                        label: "View",
                        action: 'viewResponse'
                    },
                    {
                        icon: "fa-download",
                        label: "Download",
                        action: 'download'
                    }
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: ''
                },
                isTyping: false,
                moment,
                formDetails: {
                    respondent_info: {
                        name: '',
                        email: '',
                        number: '',
                        full_address: '',
                        birthdate: '',
                    },
                    survey_info: {
                        survey_name: ''
                    },
                    custom_fields: [],
                    custom_fields_answers: {},
                    contact: {},
                },
                formPdfName: '',
                selectedRespondent: {},
            }
        },

        props: {
            contact: Object,
        },

        components:{
            ProfileCard,
            FormDetail,
        },

        watch: {
            'params.per_page' (perPage) {
                const vm = this;

                vm.params.page = 1,
                vm.getContactForms(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactForms(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactForms(vm.params);
                        }
                    }
                }
            },
        },

        computed: {
            ...mapState({
                forms: state => state.formModule.contactForms,
                formLoader: state => state.formModule.formLoader,
                user: state => state.authModule.user,
            }),
        },

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id ? vm.contact.id : '';
            vm.getContactForms(vm.params);
        },

        methods:{
            ...mapActions({
                getContactForms: 'formModule/getContactForms',
                viewFormResponse: 'formModule/viewFormResponse',
                downloadFormResponse: 'formModule/downloadFormResponse',
                enableDisableForm: 'formModule/enableDisableForm',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactForms(vm.params);
            },

            actionHandler (form, action) {
                const vm = this;

                const formData = {
                    contact_id: form.contact_id,
                    survey_id: form.survey_id,
                    respondent_id: form.respondent_id,
                    file_name: (Date.now()+'-'+form.survey_name).replaceAll(' ', '-')
                }

                if (action == 'viewResponse') {
                    vm.viewFormResponse(formData).then((result) => {
                        if (result) {
                            vm.formDetails = {
                                respondent_info: {
                                    name: result.respondent_info.name,
                                    email: result.respondent_info.email,
                                    number: result.respondent_info.number,
                                    full_address: result.respondent_info.full_address,
                                    birthdate: result.respondent_info.birthdate,
                                },
                                survey_info: result.survey_info,
                                survey_questions: result.survey_questions,
                                signatures: result.signatures,
                                custom_fields: result.custom_fields,
                                custom_fields_answers: result.custom_fields_answers,
                                contact: result.contact,
                            };

                            vm.selectedRespondent = form;
                            vm.showFormDetail = true;
                        }
                    });
                } else if (action == 'download') {
                    vm.downloadFormResponse(formData);
                }
            },

            handleEnableDisableForm (form, ev) {
                const vm = this;

                const formData = {
                    client_portal: ev.target.checked ? 1 : 0,
                    employer_id: form.employer_id,
                    survey_id: form.survey_id,
                    survey_name: form.survey_name,
                    user_id: form.contact_id,
                }

                vm.enableDisableForm(formData).then((result) => {
                    if (!result) {
                        setTimeout(function () {
                            ev.target.checked = false;
                        }, 500);
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .info_wpr{
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 45px ;
        padding: 25px 23px 35px 23px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.08); */
        position: relative;
    }
    /* .info_wpr:before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    } */
    .info_wpr .border_item{
        flex: 1 0 50%;
        padding: 15px 7px;
    }
    .info_wpr .border_item h5{
        font-size: 15px !important;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
    }
    .info_wpr .border_item p{
        /* border: 1px solid #d9d9d9; */
        padding: 0 15px;
        border-radius: 5px;
        height: 50px;
        font-size: 16px;
        line-height: 48px;
        color: #5a5a5a;
    }
    .answer_item{
        padding: 5px;
        font-size: 10px;
        line-height: 18px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        margin: 7px 0 15px;
        min-height: 30px;
    }
    .form_details{
        padding: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.08); */
        position: relative;
        margin-bottom: 45px;
    }
    /* .form_details:before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    } */
    .form_details .form_wrapper{
        display: flex;
        flex-direction: column;
        padding: 0 7px;
        border: 1px solid #edecec;
        padding: 20px 20px 10px 20px;
        border-radius: 6px;
        margin-bottom: 25px;
    }
    .form_details :deep(label), .form_details :deep(label p){
        font-size: 15px;
        line-height: 22px;
        color: #121525 !important   ;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .question_option{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 14px;
    }
    .question_option i{
        margin-right: 5px;
    }

    .edit_details{
        width: calc(100% - 320px);
        height: calc(100vh);
        background: #f5f5f5;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 3;
        animation: smoothMove 0.6s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothMove {
        from {transform: scale(0);}
        to {transform: scale(1);}
    }
    .edit_details .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #eee;
    }

    .edit_details .header h1{
        font-size: 27px;
        line-height: 33px;
        font-weight: 500;
    }
    .edit_details .header .close_btn{
        top: 20px;
        left: auto;
        right: 30px;
    }
    .edit_wpr {
        padding: 30px;
        height: 100vh;
        overflow-y: scroll;
    }
    .edit_details .close_btn{
        left: auto;
        right: 20px;
    }

    .privacy_info {
        display: flex;
        padding: 20px 25px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 30px 0 20px 0;
    }

    .privacy_info i {
        font-size: 34px;
        color: #f2a31d;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px;
    }

    .signature_fld {
        display: flex;
        justify-content: space-between;
        font-family: Caveat,cursive;
        margin: 0 0 20px;
        padding: 10px 0;
        font-size: 20px;
        line-height: 25px;
        border-bottom: 1px solid #d9d9d9;
    }

    .form_details.legal_info label p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a !important;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px !important;
    }
    @media(max-width: 480px){
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
    }
</style>
